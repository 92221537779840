import React, { useState, useEffect } from 'react';
import { Skeleton, TableBody, TableRow } from '@mui/material/';
import {
  ColoredLegend,
  StyledTableContainer,
  StyledCell,
  FlexDiv,
  StyledTable,
  SpanWidth,
} from './style';
import EnhancedTableHead, { ITableColumns } from './TableHeader';
import { useTheme } from 'styled-components';
import useIsMobile from '../../services/useIsMobile';

export interface IData {
  [key: string]: any;
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

export type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key,
): (a: { [key in Key]: number | string }, b: { [key in Key]: number | string }) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

type IEnhancedTableProps = {
  rows: IData[] | null;
  columns: ITableColumns[];
  height?: number;
  fontSize?: number;
  noScroll?: boolean;
  lineBreak?: boolean;
  headerFixed?: boolean;
  isFluxo?: boolean;
};

export default function EnhancedTable({
  rows,
  columns,
  height,
  fontSize,
  noScroll,
  lineBreak,
  headerFixed,
  isFluxo,
}: IEnhancedTableProps) {
  const [order, setOrder] = React.useState<Order>('desc');
  const [orderBy, setOrderBy] = React.useState<keyof IData>('');
  const [dynamicHeight, setDynamicHeight] = useState(isFluxo ? 0.95 * window.innerHeight - 302 : 0.97 * window.innerHeight - 274);

  const theme = useTheme();
  const isMobile = useIsMobile();

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof IData) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  useEffect(() => {
    const handleResize = () => {
      setDynamicHeight(isFluxo ? 0.95 * window.innerHeight - 302 : 0.97 * window.innerHeight - 274);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return rows && rows.length > 0 ? (
    <StyledTableContainer
      $noScroll={noScroll && !headerFixed ? true : false}
      $isMobile={isMobile}
      $dynamicHeight={dynamicHeight}
      $headerFixed={headerFixed}>
      <StyledTable
        $paddingLeft={columns && columns.some((e) => e.colorLegend)}
        size="medium"
        stickyHeader
        aria-label="sticky table">
        <EnhancedTableHead
          columns={columns}
          order={order}
          orderBy={orderBy}
          onRequestSort={handleRequestSort}
          fontSize={fontSize}
        />
        <TableBody>
          {rows
            .slice()
            .sort(getComparator(order, orderBy))
            .map((row, ind) => {
              let ativoLength = 0
              if (typeof row.nome_ativo == 'string') {
                ativoLength = row.nome_ativo.length
              } else if (typeof row.nome_ativo?.props?.children[1] == 'string') {
                ativoLength = row.nome_ativo.props.children[1].length
              }
              return (
                <TableRow
                  hover
                  onClick={() => (row.openModal ? row.openModal() : null)}
                  key={ind}
                  sx={{ cursor: row.openModal ? 'pointer' : '' }}>
                  {columns.map((column, i) => {
                    const bgc = column.bgColor ?? row.bgColor;
                    const color = column.color ?? row.color;
                    const padding = column.padding ?? row.padding;
                    const align = column.align ?? column.headerAlign;
                    const extraStyle: { [key: string]: any }  = {
                      padding: padding ?? '8px',
                      borderBottom: bgc ? '0px' : '2px solid #BEBEBE',
                      backgroundColor: bgc ?? 'white',
                      color: color ?? theme.tableCellColor,
                      borderBottomLeftRadius: column.borderLeftRadius ?? '',
                      borderTopLeftRadius: column.borderLeftRadius ?? '',
                      fontWeight: row.bold ? 'bold' : '',
                      fontSize: fontSize ?? 14,
                    };
                    if(column.cellWidth){ // adicionar tamanho limite para a celula
                      extraStyle.width = column.cellWidth;
                    }

                    return column.id in row && typeof row[column.id] !== 'undefined' ? (
                      <StyledCell
                        key={`cell-${column}-${i}`}
                        $borderRight={column.freeze || i === 0}
                        $freeze={column.freeze ?? false}
                        $leftOnFreeze={column.leftOnFreeze ?? 0}
                        $zIndex={column.freeze ? 2 : 1}
                        $paddingLeft={
                          column.freeze && typeof column.colorLegend !== 'undefined' ? true : false
                        }
                        align={align ?? 'left'}
                        sx={{
                          height: 'auto',
                          whiteSpace: lineBreak ? 'normal' : 'nowrap',
                          ...extraStyle,
                        }}
                        rowSpan={!row.separator && column.rowSpan ? column.rowSpan : 1}
                        colSpan={row.colSpan ?? 1}>
                        {column.colorLegend && i === 0 && row.colorIndex !== null ? (
                          <FlexDiv>
                            <ColoredLegend
                              colorIndex={row.colorIndex}
                              isMobile={isMobile}
                              ativoLength={ativoLength}
                              lineBreak={lineBreak}
                            />
                            <SpanWidth $width={column.width} $noBreak={column.noBreak ?? false}>
                              {row[column.id] ? row[column.id] : ''}
                            </SpanWidth>
                          </FlexDiv>
                        ) : (
                          <SpanWidth $width={column.width} $noBreak={column.noBreak ?? false}>
                            {row[column.id] ? row[column.id] : ''}
                          </SpanWidth>
                        )}
                      </StyledCell>
                    ) : null;
                  })}
                </TableRow>
              );
            })}
        </TableBody>
      </StyledTable>
    </StyledTableContainer>
  ) : (
    <Skeleton variant="rectangular" height={height} />
  );
}
