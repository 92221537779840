import { resetStateHelper, setCarteiraID, setParams, setData, setDataPersonalizada } from './preferenceSlice';

export const resetStateBuilder = (
  builder: any,
  initialState: any,
  dontChangeParameters?: string[],
  hardResetCarteira?: boolean
) => ({
  setParams: builder.addCase(setParams, (state: any) => {
    const newState = {
      ...initialState,
    };
    if (dontChangeParameters) for (const prop of dontChangeParameters) newState[prop] = state[prop];
    return newState;
  }),
  data: builder.addCase(setData, (state: any) => {
    const newState = {
      ...initialState,
    };
    if (dontChangeParameters) for (const prop of dontChangeParameters) newState[prop] = state[prop];

    return newState;
  }),
  carteiraID: builder.addCase(setCarteiraID, (state: any) => {
    const newState = {
      ...initialState,
    };
    if (dontChangeParameters && !hardResetCarteira) for (const prop of dontChangeParameters) newState[prop] = state[prop];

    return newState;
  }),
  dataPersonalizada: builder.addCase(setDataPersonalizada, (state: any) => {
    const newState = {
      ...initialState,
    };
    if (dontChangeParameters) for (const prop of dontChangeParameters) newState[prop] = state[prop];

    return newState;
  }),
  reset: builder.addCase(resetStateHelper, (state: any) => {
    const newState = {
      ...initialState,
    };
    if (dontChangeParameters) for (const prop of dontChangeParameters) newState[prop] = state[prop];

    return newState;
  }),
});

export interface IObjDatas {
  data_ini: string;
  data_fim: string;
  data_ini2: string;
  datas_nao_uteis: string;
  data_mais_antiga_portfolio_AAAAmmDD: string;
  data_mais_recente_portfolio_AAAAmmDD: string;
}