import { resetStateBuilder, IObjDatas } from './helperReducers';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchFluxoAtivos } from '../api/fluxoAtivosAPI';
import { RootState } from '../store';


export interface IFluxoAtivosState {
  isLoaded?: boolean;
  loading?: boolean;
  obj_datas: IObjDatas;
  fluxo_de_ativos?: (string | number)[][];
}

const initialState: IFluxoAtivosState = {
  isLoaded: false,
  loading: false,
  obj_datas: {
    data_ini: '',
    data_fim: '',
    data_ini2: '',
    datas_nao_uteis: '',
    data_mais_antiga_portfolio_AAAAmmDD: '',
    data_mais_recente_portfolio_AAAAmmDD: '',
  },
  fluxo_de_ativos: [],
};

export const fluxoAtivosSlice = createSlice({
  name: 'fluxoAtivos',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    resetStateBuilder(builder, initialState);

    builder.addCase(fetchFluxoAtivos.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchFluxoAtivos.fulfilled, (state, action) => {
      state.loading = false;
      state.isLoaded = true;
      state.obj_datas = action.payload.FluxoAtivos?.obj_datas;
      state.fluxo_de_ativos = action.payload.FluxoAtivos?.fluxo_de_ativos;
    });
    builder.addCase(fetchFluxoAtivos.rejected, (state) => {
      state.loading = false;
    });
  },
});

export const selectToken = (state: RootState) => state.preference.token;

export default fluxoAtivosSlice.reducer;
