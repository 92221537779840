import axios from 'axios';
import { signal } from './abortAPI';

const baseURL_prefix = `https://`;
const baseURL_default = `www`;  // aponta para produ��o por padr�o
const baseURL_suffix = `.comdinheiro.com.br/Clientes/`;

const buildCompleteURL = (url: string) =>{
  return baseURL_prefix + url + baseURL_suffix;
}

let baseURL = buildCompleteURL(window.localStorage.getItem('BASE_URL') ?? baseURL_default);
export const getBaseURL = () => baseURL;

/**
 * Definir qual � a URL das chamadas da API
 */
(window as any).setBaseURL = (newBaseURL: string) => {
  localStorage.setItem('BASE_URL', newBaseURL);
  baseURL =  buildCompleteURL(newBaseURL);
};

/**
 * Remover a URL definida no localStorage e utilizar o valor default
 */
(window as any).resetBaseURL = () => {
  localStorage.removeItem('BASE_URL');
  baseURL = buildCompleteURL(baseURL_default);
};

const api = (apiKey?: string | null, contentType?: string) => {
  const headers: { [key: string]: string } = {
    'Content-Type': contentType ?? 'application/json',
  };
  if (apiKey) headers.Authorization = `Bearer ${apiKey}`;

  const axiosVar = axios.create({
    method: 'post',
    baseURL: baseURL,
    headers,
    signal,
  });

  function isValidJson(response: any) {
    if (typeof response === 'object' && response !== null) {
      try {
        JSON.stringify(response);
        return true;
      } catch (e) {
        return false;
      }
    }
    return false;
  }

  axiosVar.interceptors.request.use(
    function (config) {
      return config;
    },
    function (error) {
      return Promise.reject(error);
    },
  );

  axiosVar.interceptors.response.use(
    function (response) {
      if (!isValidJson(response.data)) {
        window.location.replace('/');
      }
      return response;
    },
    function (error) {
      return Promise.reject(error);
    },
  );

  return axiosVar;
};

export default api;
