import { checkPeriodoPersonalizado } from './../helper';
import { AxiosError } from 'axios';
import { IResumoCartState } from './../reducers/resumoCartSlice';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../store';
import mockResumoCart from '../mock/resumoCart.json';
import api from './index';

interface ResumoCartAPI {
  ResumoCart: IResumoCartState;
  access_token?: string;
  expires_in?: number;
  msg?: string;
  codigo?: string;
}

export const fetchResumoCart = createAsyncThunk<ResumoCartAPI, void, { state: RootState }>(
  'resumoCart/fetch',
  async (nothing: void, thunkAPI) => {
    if (process.env.REACT_APP_MOCK_API === 'true') return mockResumoCart;
    try {
      const perdiodoPersonalizado = checkPeriodoPersonalizado(
        thunkAPI.getState().preference?.params.periodo,
      );

      const periodo =
        thunkAPI.getState().preference.params.periodo === 'maximo'
          ? 'data_ini_acomp'
          : thunkAPI.getState().preference.params.periodo;

      const data = JSON.stringify({
        nome_portfolio: thunkAPI.getState().preference?.params.carteira ?? '',
        data_fim: perdiodoPersonalizado ? thunkAPI.getState().preference?.dateEnd : '31/12/9999',
        data_ini: perdiodoPersonalizado
          ? (thunkAPI.getState().preference?.dateStart === 'maximo' ? 'data_ini_acomp' : thunkAPI.getState().preference?.dateStart)
          : periodo,
        // classe: 'MV(estrategia) TIPO',
        // subclasse: 'MV(risco)',
        abas: ['ResumoCart'],
        benchmarksResumoCart: thunkAPI.getState().resumoCart.selectedBenchmarks,
        recalcular: thunkAPI.getState().preference?.recalcular ? 1 : 0,
      });

      const token = thunkAPI.getState().preference?.token.value ?? '';
      const response = await api(token).post(
        `API/EndPoint002.php?code=${(window as any).env.REACT_APP_APICODE}&service=CarregaCoreExtratoWhitelabel001`,
        data,
      );

      return response.data;
    } catch (error) {
      const err = error as AxiosError;
      return thunkAPI.rejectWithValue(err.response?.data);
    }
  },
);

interface ResumoCartPerfAPI {
  ResumoCartPerf: {
    perfPorClasse: [
      {
        classe: string;
        saldo: number;
        percent: number;
        mes_perf: number;
        ano_perf: number;
      },
    ];
  };
  access_token?: string;
  expires_in?: number;
  msg?: string;
  codigo?: string;
}

export const fetchResumoCartPerf = createAsyncThunk<ResumoCartPerfAPI, void, { state: RootState }>(
  'resumoCart/fetchResumoCartPerf',
  async (nothing: void, thunkAPI) => {
    if (process.env.REACT_APP_MOCK_API === 'true') return mockResumoCart;
    try {
      const perdiodoPersonalizado = checkPeriodoPersonalizado(
        thunkAPI.getState().preference?.params.periodo,
      );

      const periodo =
        thunkAPI.getState().preference.params.periodo === 'maximo'
          ? 'data_ini_acomp'
          : thunkAPI.getState().preference.params.periodo;

      const data = JSON.stringify({
        nome_portfolio: thunkAPI.getState().preference?.params.carteira ?? '',
        data_fim: perdiodoPersonalizado ? thunkAPI.getState().preference?.dateEnd : '31/12/9999',
        data_ini: perdiodoPersonalizado
          ? (thunkAPI.getState().preference?.dateStart === 'maximo' ? 'data_ini_acomp' : thunkAPI.getState().preference?.dateStart)
          : periodo,
        abas: ['ResumoCartPerf'],
        benchmarksResumoCart: thunkAPI.getState().resumoCart.selectedBenchmarks,
        recalcular: thunkAPI.getState().preference?.recalcular ? 1 : 0,
      });

      const token = thunkAPI.getState().preference?.token.value ?? '';
      const response = await api(token).post(
        `API/EndPoint002.php?code=${(window as any).env.REACT_APP_APICODE}&service=CarregaCoreExtratoWhitelabel001`,
        data,
      );

      return response.data;
    } catch (error) {
      const err = error as AxiosError;
      return thunkAPI.rejectWithValue(err.response?.data);
    }
  },
);
