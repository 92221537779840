import React, { useEffect, useState } from 'react';
import Header from '../../../components/Header';
import { Line } from '../../../components/Line';
import { fetchResumoCart, fetchResumoCartPerf } from '../../../services/api/resumoCartAPI';
import { useAppSelector, useAppDispatch } from '../../../services/reduxHooks';
import { GraficoPizza } from '../../../components/GraficoPizza';
import EnhancedTable, { IData } from '../../../components/Table';
import { AlignTypes, ITableColumns } from '../../../components/Table/TableHeader';
import { Grid, IconButton, Switch } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faDollarSign, faBrazilianRealSign } from '@fortawesome/free-solid-svg-icons';
import { Percent } from '@mui/icons-material';
import { helperFormat, FormatTypes } from '../../../services/helper';
import { Subtitulo } from '../../../components/Titulo';
import { Cards } from '../../../components/Cards';
import useIsMobile from '../../../services/useIsMobile';
import LoadingModal from '../LoadingModal';
import { sendAnalyticsData } from '../../../services/api/preferenceAPI';
import { setSelectedBenchmarks, setIsPercentToggle } from '../../../services/reducers/resumoCartSlice';
import { useTheme } from 'styled-components';
import { fetchEstats } from '../../../services/api/estatsAPI';
import { fetchPerfHist } from '../../../services/api/perfHistAPI';
import { fetchPosCon } from '../../../services/api/posConAPI';
import { fetchCartExp } from '../../../services/api/cartExpAPI';
import { fetchIFsCaixa } from '../../../services/api/fluxoCaixaAPI';
import { fetchFluxoAtivos } from '../../../services/api/fluxoAtivosAPI';
import { CustomModal } from '../../../components/CustomModal';
import { selecionarBenchmarks } from '../../../services/helper';
import MoneyPrefix from '../../../components/MoneyPrefix';
import FixedLoadingButton from '../../../components/FixedLoadingButton';
import { useNavigate, useLocation } from 'react-router-dom';
import { styled } from '@mui/material/styles';

interface Props {
  showCards?: boolean;
  showPieChart?: boolean;
  dispatchPDF?: boolean;
}

export default function ResumoCarteira({ showCards, showPieChart, dispatchPDF }: Props) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const uselocation = useLocation();
  const [
    obj_datas,
    retorno,
    rendPorClasse,
    resumo,
    movimentacoes,
    alocClasse,
    alocCustodiante,
    loading,
    isLoaded,
    selectedBenchmarks,
    tabela_liquidez,
    dadosPercentResumo,
    loadingPercent,
    isLoadedPercent,
    isPercentToggle,
    carteiraList,
    loadedCarteiraList,
    carteira,
    isLoadingMinimized,
    params,
    preferenceCustomizacao,
  ] = useAppSelector((state) => [
    state.resumoCart.obj_datas,
    state.resumoCart.retorno,
    state.resumoCart.rendPorClasse,
    state.resumoCart.resumo,
    state.resumoCart.movimentacoes,
    state.resumoCart.alocClasse,
    state.resumoCart.alocCustodiante,
    state.resumoCart.loading,
    state.resumoCart.isLoaded,
    state.resumoCart.selectedBenchmarks,
    state.resumoCart.tabela_liquidez,
    state.resumoCart.dadosPercentResumo,
    state.resumoCart.loadingPercent,
    state.resumoCart.isLoadedPercent,
    state.resumoCart.isPercentToggle,
    state.preference.carteiraList,
    state.preference.loadedCarteiraList,
    state.preference.params.carteira,
    state.loadMinimized.isLoadingMinimized,
    state.preference.params,
    state.preference.preferenceStyle.customizacao,
  ]);

  const theme = useTheme();
  const toggleColorAbsolute = isPercentToggle ? 'gray' : theme.toggleSwitchColor;
  const toggleColorPercent = isPercentToggle ? theme.toggleSwitchColor : 'gray';

  const StyledSwitch = styled(Switch)(() => ({
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
      backgroundColor: theme.toggleSwitchColor,
    },
  }));


  const handleToggleResumo = async () => {
    await dispatch(fetchResumoCartPerf());
    dispatch(setIsPercentToggle(!isPercentToggle));
  };

  const useFormat = (value: any) =>
    helperFormat(
      value,
      isPercentToggle ? FormatTypes.percentage : FormatTypes.monetary,
      2,
      moneyPrefix,
    );

  /**
   * Selecionar benchmarks
   * @param newBenchmarks Benchmarks selecionados
   * @param historyReplace Indica se a nova navegação no state do History vai adicionar uma nova entrada ou substituir a atual no history Stack
   * No caso do benchmark inicial(preferencia do usuario), a entrada vai substituir o state atual, adicionando os benchmarks no state
   * No caso dos benchmarks selecionados, será adicionado uma nova entrada na History, para guardar a navegação desses benchmarks no history Stack
   */
  const dispatchSelectedBenchmarks = (newBenchmarks: string[], historyReplace = false) => {
    const url = `?carteira=${carteira}&periodo=${params.periodo}`
    // o parâmetro replace permite alterar o state sem adicionar uma nova entrada na history stack
    navigate(url, { replace: historyReplace, state: {...uselocation.state, bench_resumo_cart: newBenchmarks }});    // adiciona os benchmarks selecionados no state do History router
    dispatch(setSelectedBenchmarks(newBenchmarks));
  }

  const moneyPrefix = MoneyPrefix(carteiraList, carteira);

  useEffect(() => {
    if (!dispatchPDF) document.title = `${(window as any).env.REACT_APP_TABNAME} - Resumo da Carteira`;
  }, []);

  useEffect(() => {
    if (isLoaded && !dispatchPDF) dispatch(sendAnalyticsData(location.href));
  }, [params, isLoaded]);

  useEffect(() => {
    selecionarBenchmarks(loadedCarteiraList, carteiraList, carteira, dispatchPDF, selectedBenchmarks, 'resumoCart', dispatchSelectedBenchmarks);
  }, [loadedCarteiraList]);

  useEffect(() => {
    async function loadDados() {
      const dispatchArray: any[] = [
        dispatch(fetchResumoCart()),
      ];
      if(preferenceCustomizacao?.ResCart_percent ?? false) dispatchArray.push(dispatch(fetchResumoCartPerf()))
      await Promise.all(dispatchArray); // executar as duas chamadas ao mesmo tempo
    }
    if (loadedCarteiraList && !isLoaded && !loading && !dispatchPDF) {  // precisa do carteiraList para carregar benchs e apelido
      loadDados();

      dispatch(fetchPerfHist());
      dispatch(fetchPosCon());
      dispatch(fetchCartExp());
      dispatch(fetchFluxoAtivos('mes_atual'));
      dispatch(fetchIFsCaixa());
      dispatch(fetchEstats());
    }
  }, [isLoaded, loadedCarteiraList]);

  /**
   * Carregar os benchmarks quando a página for recarregada
   */
  useEffect(() => {
      const bench_history = uselocation?.state?.bench_resumo_cart ?? null;
      if(bench_history){
        dispatch(setSelectedBenchmarks(bench_history));
      }
  }, []);

  const [openModal, setOpenModal] = useState(false);
  const [ativoModal, setAtivoModal] = useState('');
  const [modalType, setModalType] = useState('');

  const onClasseHandle = (value: any) => {
    setOpenModal(!openModal);
    setModalType('classe');
    if (value) setAtivoModal(value);
  };

  const onCustodianteHandle = (value: any) => {
    setOpenModal(!openModal);
    setModalType('custodiante');
    if (value) setAtivoModal(value);
  };

  const cardsData =
    isLoaded && resumo
      ? [
          {
            title: `${preferenceCustomizacao?.ResCart_textoCard1 ?? 'Saldo'} ${resumo?.datas.data_ini.split('-').reverse().join('/')}`,
            value: helperFormat(resumo?.saldoIni, FormatTypes.monetary, 2, moneyPrefix),
          },
          {
            title: 'Movimentações',
            value: helperFormat(resumo?.movimentacoes, FormatTypes.monetary, 2, moneyPrefix),
          },
          {
            title: 'Impostos Pagos',
            value: helperFormat(resumo?.impostospagos, FormatTypes.monetary, 2, moneyPrefix),
          },
          {
            title: 'Rendimento Bruto',
            value: helperFormat(resumo?.rendimentoBruto, FormatTypes.monetary, 2, moneyPrefix),
          },
          {
            title: `${preferenceCustomizacao?.ResCart_textoCard5 ?? 'Saldo'} ${resumo?.datas.data_fim.split('-').reverse().join('/')}`,
            value: helperFormat(resumo?.saldoFim, FormatTypes.monetary, 2, moneyPrefix),
          },
        ]
      : null;

  const cardsDataMobile =
    isLoaded && resumo
      ? [
          {
            title: `Saldo ${resumo?.datas.data_ini.split('-').reverse().join('/')}`,
            value: helperFormat(resumo?.saldoIni, FormatTypes.monetary, 2, moneyPrefix),
          },
          {
            title: `Saldo ${resumo?.datas.data_fim.split('-').reverse().join('/')}`,
            value: helperFormat(resumo?.saldoFim, FormatTypes.monetary, 2, moneyPrefix),
          },
          {
            title: 'Movimentações',
            value: helperFormat(resumo?.movimentacoes, FormatTypes.monetary, 2, moneyPrefix),
          },
          {
            title: 'Impostos Pagos',
            value: helperFormat(resumo?.impostospagos, FormatTypes.monetary, 2, moneyPrefix),
          },
          {
            title: 'Rendimento Bruto',
            value: helperFormat(resumo?.rendimentoBruto, FormatTypes.monetary, 2, moneyPrefix),
          },
        ]
      : null;

  type formatType = FormatTypes.percentage | FormatTypes.monetary;

  const rowsRetorno =
    isLoaded && retorno
      ? retorno.map((ret) => {
          let formatType: formatType = FormatTypes.percentage;
          let isBold = false;
          if (ret.descricao === 'Rend. Nominal') formatType = FormatTypes.monetary;
          if((preferenceCustomizacao?.ResCart_boldRetornoNomial ?? false) && (ret.descricao === 'Rend. Nominal' || ret.descricao === "Var. Nominal")){
            isBold = true;
          }
          return {
            ativo: ret.descricao,
            mes: helperFormat(ret.mes, formatType, 2, moneyPrefix),
            ano: helperFormat(ret.ano, formatType, 2, moneyPrefix),
            mes12: helperFormat(ret.m12, formatType, 2, moneyPrefix),
            mes24: helperFormat(ret.m24, formatType, 2, moneyPrefix),
            periodo: helperFormat(ret.periodo, formatType, 2, moneyPrefix),
            bold: isBold,
          };
        })
      : null;

  const columnsRetorno: ITableColumns[] = [
    {
      id: 'ativo',
      label: 'Ativo',
      freeze: true,
    },
    {
      id: 'mes',
      label: 'Mês',
      align: AlignTypes.right,
    },
    {
      id: 'ano',
      label: 'Ano',
      align: AlignTypes.right,
    },
    {
      id: 'mes12',
      label: '12 meses',
      align: AlignTypes.right,
    },
    {
      id: 'mes24',
      label: '24 meses',
      align: AlignTypes.right,
    },
  ];

  if (params.periodo !== '12m' && params.periodo !== '24m' && params.periodo !== 'mes_atual')
    columnsRetorno.push({
      id: 'periodo',
      label: params.periodo === 'maximo' ? 'Desde o início' : 'Período',
      align: AlignTypes.right,
    });
  let checkLoaded = isLoaded;
  if(isPercentToggle || (preferenceCustomizacao?.ResCart_percent ?? false)){
    checkLoaded = isLoaded && isLoadedPercent;
  }
  const rowsClasse =
    checkLoaded && rendPorClasse ?
      rendPorClasse.map((classe, ind) => {
        const colorIndex =
          classe.classe !== 'Caixa Bloqueado' &&
          classe.classe !== 'Total da Carteira' &&
          classe.classe !== 'Total Disponível' &&
          classe.classe !== 'Total' ? ind  : false;
        const row: {[key: string]: any} = {
          classe: classe.classe,
          saldo: helperFormat(classe.saldo, FormatTypes.monetary, 2, moneyPrefix),
          percent: helperFormat(Number(classe.percent) * 100, FormatTypes.percentage, 2),
          mes: isPercentToggle ? useFormat(dadosPercentResumo?.[ind].mes_perf) : useFormat(classe.mes),
          ano: isPercentToggle ? useFormat(dadosPercentResumo?.[ind].ano_perf) : useFormat(classe.ano),
          colorIndex,
          bold: classe.classe === 'Total Disponível' || classe.classe === 'Total da Carteira' || classe.classe === 'Total da Carteira' || classe.classe === 'Total',
        };
        if(preferenceCustomizacao?.ResCart_percent ?? false){
          row.mesPerf = helperFormat(dadosPercentResumo?.[ind].mes_perf, FormatTypes.percentage, 2)
          row.anoPerf = helperFormat(dadosPercentResumo?.[ind].ano_perf, FormatTypes.percentage, 2)
        }
        return row;
      }): null;

  let columnsClasse: ITableColumns[] = [];
  if((preferenceCustomizacao?.ResCart_percent ?? false)){
    columnsClasse = [
      {
        id: 'classe',
        label: 'Classe',
        orderBy: 'classe',
        colorLegend: true,
        freeze: true,
      },
      {
        id: 'percent',
        label: '%',
        align: AlignTypes.right,
      },
      {
        id: 'saldo',
        label: 'Saldo Bruto',
        align: AlignTypes.right,
      },
      {
        id: 'mes',
        label: `No Mês (${moneyPrefix})`,
        align: AlignTypes.right,
      },
      {
        id: 'ano',
        label: `No Ano (${moneyPrefix})`,
        align: AlignTypes.right,
      },
      {
        id: 'mesPerf',
        label: 'No Mês (%)',
        align: AlignTypes.right,
      },
      {
        id: 'anoPerf',
        label: 'No Ano (%)',
        align: AlignTypes.right,
      },
    ];
  }else{
    columnsClasse = [
      {
        id: 'classe',
        label: 'Classe',
        orderBy: 'classe',
        colorLegend: true,
        freeze: true,
      },
      {
        id: 'saldo',
        label: 'Saldo Bruto',
        align: AlignTypes.right,
      },
      {
        id: 'percent',
        label: '%',
        align: AlignTypes.right,
      },
      {
        id: 'mes',
        label: 'No Mês',
        align: AlignTypes.right,
      },
      {
        id: 'ano',
        label: 'No Ano',
        align: AlignTypes.right,
      },
    ];
  }

  let rowsMovim: IData[] = [];
  let columnsMovim: ITableColumns[] = [];
  if(!(preferenceCustomizacao?.ResCart_removerTabMovim ?? false)){
    rowsMovim = isLoaded && movimentacoes ?
      movimentacoes.map((movim) => {
        return {
          mesano: movim.data,
          saldoMovim: helperFormat(movim.saldoMovim, FormatTypes.monetary, 2, moneyPrefix),
          saldoBruto: helperFormat(movim.saldoBruto, FormatTypes.monetary, 2, moneyPrefix),
        };
      }) : [];
    columnsMovim = [
      {
        id: 'mesano',
        label: 'Mês/Ano',
      },
      {
        id: 'saldoMovim',
        label: 'Saldo Movimentações',
        align: AlignTypes.right,
      },
      {
        id: 'saldoBruto',
        label: 'Saldo Bruto',
        align: AlignTypes.right,
      },
    ];
  }

  const rowsLiq =
    isLoaded && tabela_liquidez
      ? tabela_liquidez.slice(1).map((arrayLiq) => {
          return {
            periodo: arrayLiq[0],
            valor: helperFormat(arrayLiq[1], FormatTypes.decimals),
            percent: helperFormat(Number(arrayLiq[2]) * 100, FormatTypes.decimals),
          };
        })
      : null;

  const columnsLiq: ITableColumns[] = [
    {
      id: 'periodo',
      label: 'Tempo de Resgate (DC)',
    },
    {
      id: 'valor',
      label: 'Valor Líquido',
      align: AlignTypes.right,
    },
    {
      id: 'percent',
      label: '%',
      align: AlignTypes.right,
    },
  ];

  const rowsTabelaPorClasse: { [key: string]: any }[] = [];
  alocClasse
    ?.filter(
      (e) =>
        (e.classe && e.classe === ativoModal) ||
        (e.classe === '' && ativoModal.toLowerCase() === 'outros'),
    )
    .forEach((classe) => {
      let sbr_total = 0;
      classe.ativos.forEach((atv) => {
        sbr_total += atv.sbr_fim;
        rowsTabelaPorClasse.push({
          ativo: atv.nome_ativo,
          aloc: helperFormat(atv.alocacao * 100, FormatTypes.percentage),
          sbr: helperFormat(atv.sbr_fim, FormatTypes.monetary, 2, moneyPrefix),
        });
      });

      rowsTabelaPorClasse.unshift({
        ativo: ativoModal,
        aloc: helperFormat(classe.alocacao * 100, FormatTypes.percentage),
        sbr: helperFormat(sbr_total, FormatTypes.monetary, 2, moneyPrefix),
        bgColor: '#E7E7E7',
        color: theme.fontColor,
        bold: true,
      });
    });

  const rowsTabelaPorCustodiante: { [key: string]: any }[] = [];
  alocCustodiante
    ?.filter(
      (e) =>
        (e.custodiante && e.custodiante === ativoModal) ||
        (e.custodiante === '' && ativoModal.toLowerCase() === 'outros'),
    )
    .forEach((custodiante) => {
      let sbr_total = 0;
      custodiante.ativos.forEach((atv) => {
        sbr_total += atv.sbr_fim;
        rowsTabelaPorCustodiante.push({
          ativo: atv.nome_ativo,
          aloc: helperFormat(atv.alocacao * 100, FormatTypes.percentage),
          sbr: helperFormat(atv.sbr_fim, FormatTypes.monetary, 2, moneyPrefix),
        });
      });

      rowsTabelaPorCustodiante.unshift({
        ativo: ativoModal,
        aloc: helperFormat(custodiante.alocacao * 100, FormatTypes.percentage),
        sbr: helperFormat(sbr_total, FormatTypes.monetary, 2, moneyPrefix),
        bgColor: '#E7E7E7',
        color: theme.fontColor,
        bold: true,
      });
    });

    const pieChartClassData =
      isLoaded && rendPorClasse ?
        rendPorClasse.filter((obj) =>
          obj.classe !== 'Caixa Bloqueado' &&
          obj.classe !== 'Total da Carteira' &&
          obj.classe !== 'Total Disponível' &&
          obj.classe !== 'Total',
        ).map((obj, index) => ({
          name: obj.classe,
          value:
            typeof obj.percent === 'number'
              ? Number((obj.percent * 100).toFixed(2))
              : Number((Number(obj.percent) * 100).toFixed(2)),
            color: theme.chartColors[index % theme.chartColors.length],
        })).sort((a, b) => (a.value > b.value ? -1 : b.value > a.value ? 1 : 0))
    : null;

    let pieChartCustodianteData = null;
    if(isLoaded && alocCustodiante){
      pieChartCustodianteData = alocCustodiante.map((custodiante) => {
        // valor em porcentagem
        let value = typeof custodiante.alocacao === 'number'? Number((custodiante.alocacao * 100).toFixed(2)) : Number((Number(custodiante.alocacao) * 100).toFixed(2));
        if(preferenceCustomizacao?.ResCart_graficoAlocacaoVolume ?? false){
          // calcula o volume custodiante (Soma do Saldo bruto de todos os ativos do Custodiante)
          value = custodiante.ativos.reduce((total: number, atv: { sbr_fim: number }) => total + atv.sbr_fim, 0);
        }
        return {
          name: custodiante.custodiante || 'Outros',
          value: value
        }
      }).sort((a, b) => (a.value > b.value ? -1 : b.value > a.value ? 1 : 0));
    }

  const colsModal: ITableColumns[] = [
    {
      id: 'ativo',
      label: 'Ativo',
      width: '300px',
    },
    {
      id: 'aloc',
      label: 'Alocação',
      align: AlignTypes.right,
    },
    {
      id: 'sbr',
      label: 'Saldo Bruto',
      align: AlignTypes.right,
    },
  ];

  const renderTogglePercent = (
    <Grid item style={{ marginLeft: 'auto' }}>
      <IconButton style={{ color: toggleColorAbsolute }} onClick={handleToggleResumo} disabled={isLoadingMinimized}>
        <FontAwesomeIcon
          icon={faDollarSign as IconProp}
          style={isLoadingMinimized ? { color: theme.disabledColor} : {color: toggleColorAbsolute}}
          size="xs"
        />
      </IconButton>
      <StyledSwitch
        checked={isPercentToggle}
        onChange={handleToggleResumo}
        style={isLoadingMinimized ? { color: theme.disabledColor} : {color: theme.toggleSwitchColor}}
        disabled={isLoadingMinimized}
      />
      <IconButton style={isLoadingMinimized ? { color: theme.disabledColor} : {color: toggleColorPercent}} onClick={handleToggleResumo} disabled={isLoadingMinimized}>
        <Percent />
      </IconButton>
    </Grid>
  );

  const renderTabRetorno = (
    <Grid item xs={12}
      md={preferenceCustomizacao?.ResCart_percent &&
        !(preferenceCustomizacao?.ResCart_removerTabMovim && preferenceCustomizacao?.ResCart_removerTabFluxoResgate) ? 8 : 12
      }
    >
      <Subtitulo mb="8px">Retorno da Carteira x Benchmarks. Período de análise: de {obj_datas.data_ini} a {obj_datas.data_fim}</Subtitulo>
      <EnhancedTable rows={rowsRetorno} columns={columnsRetorno} height={300} />
    </Grid>
  );

  const renderTabPosicao = (
    <Grid container item xs={12} sx={{ marginTop: 3 }}>
      <Subtitulo mb="8px">
        Posição e {isPercentToggle ? 'Atribuição de Performance' : 'Rendimento'} por Classe
      </Subtitulo>
      {preferenceCustomizacao?.ResCart_percent ?? false ? null : renderTogglePercent}
      <EnhancedTable rows={rowsClasse} columns={columnsClasse} height={300} />
    </Grid>
  )

  const renderTabsFluxoEMov = (
    <Grid item xs={12} md={4}>
      {preferenceCustomizacao?.ResCart_removerTabFluxoResgate ?? false ? null:
        <>
          <Subtitulo mb="8px">Fluxo de Resgate</Subtitulo>
          <EnhancedTable rows={rowsLiq} columns={columnsLiq} height={650} noScroll={true} />
        </>
      }
      {preferenceCustomizacao?.ResCart_removerTabMovim ?? false ? null:
        <>
          <Subtitulo mb="8px">Resumo de Movimentações</Subtitulo>
          <EnhancedTable rows={rowsMovim} columns={columnsMovim} height={650} noScroll={true} />
        </>
      }
    </Grid>
  );

  const elementTabelas = preferenceCustomizacao?.ResCart_percent ?? false ?
  (
  <Grid container spacing={3} sx={{ marginTop: 3 }}>
    <Grid item xs={12}>
      <Grid container spacing={3}>
        {renderTabRetorno}
        {renderTabsFluxoEMov}
      </Grid>
      {renderTabPosicao}
    </Grid>
  </Grid>
  ) : (
  <Grid container spacing={3} sx={{ marginTop: 3 }}>
    <Grid item xs={12} md={8}>
      {renderTabRetorno}
      {renderTabPosicao}
    </Grid>
    {renderTabsFluxoEMov}
  </Grid>
  );

  return (
    <>
      <Grid>
        <Header
          title="Resumo da Carteira"
          containerAux
          benchmarks={{ selectedBenchmarks, dispatchSelectedBenchmarks }}
          periodo
          obj_datas={obj_datas}
        />
        <Line />
      </Grid>
      <Grid container columns={1} display={showCards ? 'grid' : 'none'}>
        <Cards cards={useIsMobile() ? cardsDataMobile : cardsData} />
      </Grid>
      {elementTabelas}
      <Grid
        container
        spacing={2}
        justifyContent="center"
        alignItems="center"
        display={showPieChart ? 'flex' : 'none'}>
        {(pieChartClassData && pieChartClassData.length > 0 && !isNaN(pieChartClassData[0].value)) ? <Grid item xs={12} lg={6} xl={6} marginBottom={{xs:15, lg:0}}>
          <Subtitulo mb="8px" center>
            Alocação por Classe
          </Subtitulo>
          <GraficoPizza series={pieChartClassData} height={550} onClickHandle={onClasseHandle} />
        </Grid> : null}
        {(preferenceCustomizacao?.ResCart_mostrarGraficoAlocacao ?? true) && (pieChartCustodianteData && pieChartCustodianteData.length > 0) ? <Grid item xs={12} lg={6} xl={6}>
          <Subtitulo mb="8px" center>
            Alocação por Custodiante
          </Subtitulo>
          <GraficoPizza
            series={pieChartCustodianteData}
            height={550}
            onClickHandle={onCustodianteHandle}
            FormatTypeParam = {preferenceCustomizacao?.ResCart_graficoAlocacaoVolume ?? false ? FormatTypes.monetary_no_space : FormatTypes.percentage}
          />
        </Grid> : null}
      </Grid>

      <CustomModal
        open={openModal}
        onCloseHandle={() => setOpenModal(!openModal)}
        width="fit-content">
        <EnhancedTable
          rows={modalType === 'classe' ? rowsTabelaPorClasse : rowsTabelaPorCustodiante}
          columns={colsModal}
          height={300}
        />
      </CustomModal>
      <LoadingModal loading={((!isLoaded || loadingPercent) && !isLoadingMinimized) ?? false} />
      <FixedLoadingButton loading={(!isLoaded || loadingPercent) ?? false} isLoadingMinimized={isLoadingMinimized}/>
    </>
  );
}
