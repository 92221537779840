import { resetStateBuilder, IObjDatas } from './helperReducers';
import { createSlice } from '@reduxjs/toolkit';
import { fetchEstats } from '../api/estatsAPI';
import { RootState } from '../store';

interface IInfosAdd {
  retorno?: (string | number)[][];
  pl_data_fim?: number;
  pl_medio?: number;
  comparativo?: (string | number)[][];
}

export interface IEstatsState {
  isLoaded: boolean;
  loading: boolean;
  obj_datas: IObjDatas;
  infos_add: IInfosAdd;
  retorno_carteira_bench: (string | number)[][];
  vol_carteira_bench: (string | number)[][];
  sharpe_carteira_bench: (string | number)[][];
}

const initialState: IEstatsState = {
  isLoaded: false,
  loading: false,
  obj_datas: {
    data_ini: '',
    data_fim: '',
    data_ini2: '',
    datas_nao_uteis: '',
    data_mais_antiga_portfolio_AAAAmmDD: '',
    data_mais_recente_portfolio_AAAAmmDD: '',
  },
  infos_add: {},
  retorno_carteira_bench: [],
  vol_carteira_bench: [],
  sharpe_carteira_bench: [],
};

export const estatsSlice = createSlice({
  name: 'estats',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    resetStateBuilder(builder, initialState, ['IF', 'listaIFs'], true);

    builder.addCase(fetchEstats.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchEstats.fulfilled, (state, action) => {
      state.loading = false;
      state.isLoaded = true;
      state.obj_datas = action.payload.EstatAvanc?.obj_datas;
      state.infos_add = action.payload.EstatAvanc?.infos_add;
      state.retorno_carteira_bench = action.payload.EstatAvanc?.retorno_carteira_bench;
      state.vol_carteira_bench = action.payload.EstatAvanc?.vol_carteira_bench;
      state.sharpe_carteira_bench = action.payload.EstatAvanc?.sharpe_carteira_bench;
    });
    builder.addCase(fetchEstats.rejected, (state) => {
      state.loading = false;
    });
  },
});

export const selectToken = (state: RootState) => state.preference.token;

export default estatsSlice.reducer;
