import React, { useEffect, useState } from 'react';
import { useTheme } from 'styled-components';
import { MenuItem, FormControl, Select, Checkbox, ListItemText } from '@mui/material';
import { ReactComponent as ChartLine } from '../../assets/chart-line.svg';
import { Container } from '../Container';
import { ButtonWrapper, SelectColumn, SelectWrapper, StyledButton } from './style';
import useIsMobile from '../../services/useIsMobile';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faSquare, faSquareCheck } from '@fortawesome/free-regular-svg-icons';
import { useAppSelector } from '../../services/reduxHooks';

interface IProps {
  selectedBenchmarks: string[] | null;
  dispatchSelectedBenchmarks: (newBenchmarks: string[], historyReplace?: boolean) => void;
}

const defaultOptions = [
  { label: 'CDI', value: 'CDI' },
  { label: '% do CDI', value: 'percent(CDI)' },
  { label: 'IBOV', value: 'IBOV' },
  { label: 'Excesso sobre o IBOV', value: 'exc(IBOV)' },
  { label: 'IBRX', value: 'IBRX' },
  { label: 'IPCA', value: 'IPCAdp' },
  { label: 'IPCA+4', value: 'IPCAdp+4%aa' },
  { label: 'IPCA+5', value: 'IPCAdp+5%aa' },
  { label: 'IPCA+6', value: 'IPCAdp+6%aa' },
  { label: 'Poupança', value: 'POUPANCA1d' },
];

let customItemOptions: { value: string; label: string }[] = [];

export default function Benchmarks({ selectedBenchmarks, dispatchSelectedBenchmarks }: IProps) {
  const isMobile = useIsMobile();
  const theme = useTheme();
  const [option] = useState<string[]>([]);
  const [openSelect, setOpenSelect] = useState(false);
  const [benchmark, setBenchmark] = useState<string[]>([]);

  const [carteiraList, loadedCarteiraList, carteira, isLoadingMinimized] = useAppSelector((state) => [
    state.preference.carteiraList,
    state.preference.loadedCarteiraList,
    state.preference.params.carteira,
    state.loadMinimized.isLoadingMinimized,
  ]);

  useEffect(() => {
    if (openSelect) {
      setBenchmark(selectedBenchmarks ? [...selectedBenchmarks] : []);
    }
  }, [openSelect]);

  useEffect(() => {
    if (loadedCarteiraList && carteiraList && carteiraList[0] && carteira)
      customItemOptions =
        carteiraList.filter((item) => item.nome_portfolio.toUpperCase() === carteira.toUpperCase())[0]?.CV_bench_opcao ?? [];
  }, [carteiraList, carteira]);

  const menuItemOptions =
    customItemOptions && customItemOptions.length > 0 ? customItemOptions : defaultOptions;

  const handleClick = () => {
    dispatchSelectedBenchmarks([...benchmark], false);
    setOpenSelect(false);
  };

  function MenuItemStyles() {
    return {
      backgroundColor: theme.dropSelectorColor,
      color: theme.backgroundColorSecondary,
      fontFamily: 'Open-sans',
      marginTop: -8,
      marginBottom: -8,
    };
  }

  const clickOnBench = (bench: string) => {
    if (benchmark.includes(bench)) {
      const arr = [...benchmark];
      arr.splice(benchmark.indexOf(bench), 1);
      setBenchmark(arr);
    } else {
      setBenchmark([...benchmark, bench]);
    }
  };

  const avoidPropagation = (e: React.MouseEvent<HTMLElement>) => {
    if (e.stopPropagation) {
      e.stopPropagation();
    } else if (window.event) {
      window.event.cancelBubble = true;
    }
  };

  return (
    <Container>
      <FormControl variant="standard" style={{ display: 'flex', flexDirection: 'row' }}>
        <Select
          disabled={isLoadingMinimized}
          id="demo-multiple-checkbox-label"
          multiple
          MenuProps={{
            PaperProps: {
              style: {
                overflowY: 'hidden',
                backgroundColor: theme.dropSelectorColor,
              },
            },
          }}
          open={openSelect}
          onClick={() => {
            if(!isLoadingMinimized){  // n�o permitir utilizar bot�o quando minizar o modal de loading
              setOpenSelect(!openSelect);
            }
          }}
          displayEmpty
          value={option}
          renderValue={() => {
            return <div style={{ color: theme.titleFontColor }}>Benchmarks</div>;
          }}
          inputProps={{
            'aria-label': 'Without label',
            IconComponent: () => <ChartLine width="25px" fill={isLoadingMinimized ? theme.disabledColor : theme.iconsColor} />,
          }}>
          <SelectWrapper>
            <SelectColumn
              onClick={(e: React.MouseEvent<HTMLElement>) => {
                avoidPropagation(e);
                setOpenSelect(true);
              }}>
              {menuItemOptions
                .slice(0, isMobile ? 0 : Math.ceil(menuItemOptions.length / 2))
                .map((item) => (
                  <MenuItem
                    style={MenuItemStyles()}
                    key={item.label}
                    value={item.value}
                    onClick={() => {
                      clickOnBench(item.value);
                    }}>
                    <Checkbox
                      checked={benchmark.includes(item.value)}
                      icon={
                        <FontAwesomeIcon
                          icon={faSquare as IconProp}
                          style={{ color: theme.backgroundColorSecondary }}
                          fontSize={'1.5rem'}
                        />
                      }
                      checkedIcon={
                        <FontAwesomeIcon
                          icon={faSquareCheck as IconProp}
                          style={{ color: theme.backgroundColorSecondary }}
                          fontSize={'1.5rem'}
                        />
                      }
                    />
                    <ListItemText primary={item.label} />
                  </MenuItem>
                ))}
            </SelectColumn>
            <SelectColumn
              onClick={(e: React.MouseEvent<HTMLElement>) => {
                avoidPropagation(e);
                setOpenSelect(true);
              }}>
              {menuItemOptions
                .slice(isMobile ? 0 : Math.ceil(menuItemOptions.length / 2), menuItemOptions.length)
                .map((item) => (
                  <MenuItem
                    style={MenuItemStyles()}
                    key={item.label}
                    value={item.value}
                    onClick={() => {
                      clickOnBench(item.value);
                    }}>
                    <Checkbox
                      checked={benchmark.includes(item.value)}
                      icon={
                        <FontAwesomeIcon
                          icon={faSquare as IconProp}
                          style={{ color: theme.backgroundColorSecondary }}
                          fontSize={'1.5rem'}
                        />
                      }
                      checkedIcon={
                        <FontAwesomeIcon
                          icon={faSquareCheck as IconProp}
                          style={{ color: theme.backgroundColorSecondary }}
                          fontSize={'1.5rem'}
                        />
                      }
                    />
                    <ListItemText primary={item.label} />
                  </MenuItem>
                ))}
              <ButtonWrapper>
                <StyledButton
                  onClick={(e: React.MouseEvent<HTMLElement>) => {
                    avoidPropagation(e);
                    handleClick();
                  }}>
                  Aplicar
                </StyledButton>
              </ButtonWrapper>
            </SelectColumn>
          </SelectWrapper>
        </Select>
      </FormControl>
    </Container>
  );
}
