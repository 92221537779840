/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState, useRef } from 'react';
import { useTheme } from 'styled-components';
import { Grid } from '@mui/material';
import { Line } from '../../../components/Line';
import Header from '../../../components/Header';
import EnhancedTable from '../../../components/Table';
import { AlignTypes, ITableColumns } from '../../../components/Table/TableHeader';
import { GraficoPizza } from '../../../components/GraficoPizza';
import { useAppDispatch, useAppSelector } from '../../../services/reduxHooks';
import {
  auxFormatPercent,
  FormatTypes,
  groupAndSumByProperties,
  helperFormat,
  hexToRGBA,
  sumByProperties,
} from '../../../services/helper';
import { fetchCartExp } from '../../../services/api/cartExpAPI';
import { Subtitulo, SmallerTitle } from '../../../components/Titulo';
import LoadingModal from '../LoadingModal';
import FixedLoadingButton from '../../../components/FixedLoadingButton';

import { sendAnalyticsData } from '../../../services/api/preferenceAPI';

import { fetchFluxoAtivos } from '../../../services/api/fluxoAtivosAPI';
import { fetchPerfHist } from '../../../services/api/perfHistAPI';
import { fetchResumoCart, fetchResumoCartPerf } from '../../../services/api/resumoCartAPI';
import { fetchEstats } from '../../../services/api/estatsAPI';
import { fetchPosCon } from '../../../services/api/posConAPI';
import ModalAviso from './ModalAviso/';
import ModalInfo from './ModalInfo/';

import { ContainerInfo, BotaoAbrirModal, ContainerSubtitle } from './style';

const HEIGHT_BREAKPOINT = 650;

interface Props {
  dispatchPDF?: boolean;
}

export default function CarteiraExplodida({ dispatchPDF }: Props) {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const [openModalAviso, setOpenModalAviso] = useState(false);
  const [openModalInfo, setOpenModalInfo] = useState(false);
  const [
    preferenceCustomizacao,
    dados_cart_explodida,
    aviso_carteira_explodida,
    obj_datas,
    isLoaded,
    loading,
    isLoadingMinimized,
    params,
  ] = useAppSelector((state) => [
    state.preference.preferenceStyle.customizacao,
    state.cartExp.dados_cart_explodida,
    state.cartExp.aviso_carteira_explodida,
    state.cartExp.obj_datas,
    state.cartExp.isLoaded,
    state.cartExp.loading,
    state.loadMinimized.isLoadingMinimized,
    state.preference.params,
  ]);

  useEffect(() => {
    if (!dispatchPDF) document.title = `${(window as any).env.REACT_APP_TABNAME} - Carteira Explodida`;
  }, []);

  useEffect(() => {
    if (isLoaded && !dispatchPDF) dispatch(sendAnalyticsData(location.href));
  }, [params, isLoaded]);

  useEffect(() => {
    async function loadDados() {
      setOpenModalAviso(false);  // sempre resetar o valor quando carregar os dados
      setOpenModalInfo(false);
      await dispatch(fetchCartExp());
    }
    if ((!isLoaded && !loading) && !dispatchPDF) {
      loadDados();

      dispatch(fetchResumoCart());
      if(preferenceCustomizacao?.ResCart_percent ?? false) dispatch(fetchResumoCartPerf());
      dispatch(fetchPerfHist());
      dispatch(fetchPosCon());
      dispatch(fetchFluxoAtivos('mes_atual'));
      dispatch(fetchEstats());
    }
  }, [isLoaded]);

  // colunas da tabela
  const columnsDadosAgrupados: ITableColumns[] = [
    {
      id: 'nome_ativo',
      label: 'Ativo Final',
      colorLegend: true,
      freeze: true,
      width: '300px',
      noBreak: true,
    },
    {
      id: 'sbr_fim',
      label: `Saldo Bruto`,
      align: AlignTypes.right,
      headerAlign: AlignTypes.right,
    },
    {
      id: 'percent_sbr',
      label: '% do Saldo Bruto',
      align: AlignTypes.right,
      headerAlign: AlignTypes.right,
    },
  ];

  const dadosAgrupados =
    isLoaded && dados_cart_explodida
      ? groupAndSumByProperties(dados_cart_explodida, 'classe', 'subclasse', [
        'sbr_fim',
        'percent_sbr',
      ])
      : [];

  const totalAtivos =
    isLoaded && dados_cart_explodida
      ? sumByProperties(dados_cart_explodida, [
        'sbr_fim',
        'percent_sbr',
      ])
      : [];

  const rowsDadosAgrupados: any[] = [];
  const alocClasse: any[] = [];   // dados para grafico pizza

  dadosAgrupados.forEach((classe, index) => {
    // linha classe
    if (classe.class) rowsDadosAgrupados.push({
      nome_ativo: classe.class,
      colorIndex: index,
      bgColor: '#444444',
      color: theme.backgroundColor,
      bold: true,
      sbr_fim: '',
      percent_sbr: '',
      openModal: null,
    });

    classe.subclasses.forEach((subclasse) => {
      // linha subclasse
      if (subclasse.subclass) rowsDadosAgrupados.push({
        nome_ativo: subclasse.subclass,
        bgColor: '#E7E7E7',
        color: theme.fontColor,
        bold: true,
        colorIndex: index,
        sbr_fim: '',
        percent_sbr: '',
        openModal: null,
      });

      subclasse.values.forEach((ativo) => {
        // ativo
        rowsDadosAgrupados.push({
          nome_ativo: ativo.nome_ativo,
          sbr_fim: helperFormat(ativo.sbr_fim, FormatTypes.decimals, 2),
          percent_sbr: helperFormat(auxFormatPercent(ativo.percent_sbr), FormatTypes.decimals, 2),
          colorIndex: index,
        });
      });

      // total subclasse
      if (subclasse.subclass) rowsDadosAgrupados.push({
        nome_ativo: `Total ${subclasse.subclass}`,
        sbr_fim: helperFormat(subclasse.subtotal.sbr_fim, FormatTypes.decimals, 2),
        percent_sbr: helperFormat(
          auxFormatPercent(subclasse.subtotal.percent_sbr),
          FormatTypes.decimals,
          2,
        ),
        colorIndex: index,
        // bgColor: theme.chartLighterColors[index % theme.chartLighterColors.length],
        bgColor: hexToRGBA(theme.chartColors[index % theme.chartColors.length], theme.hexAlphaLighter),
        color: theme.fontColor,
        bold: true,
        openModal: null,
      });
    });

    // total classe
    if (classe.class) rowsDadosAgrupados.push({
      nome_ativo: `Total ${classe.class}`,
      sbr_fim: helperFormat(classe.subtotal.sbr_fim, FormatTypes.decimals, 2),
      percent_sbr: helperFormat(
        auxFormatPercent(classe.subtotal.percent_sbr),
        FormatTypes.decimals,
        2,
      ),
      colorIndex: index,
      // bgColor: theme.chartLightColors[index % theme.chartLightColors.length],
      bgColor: hexToRGBA(theme.chartColors[index % theme.chartColors.length], theme.hexAlphaLight),
      color: theme.fontColor,
      bold: true,
      openModal: null,
    });

    // dados para grafico pizza(total da classe)
    if (classe.class) alocClasse.push({
      classe: `${classe.class}`,
      alocacao: classe.subtotal.percent_sbr,
    });
  });
  if (isLoaded && dados_cart_explodida)
    rowsDadosAgrupados.push({
      nome_ativo: 'Total',
      bgColor: '#444444',
      color: theme.backgroundColor,
      bold: true,
      sbr_fim: helperFormat(totalAtivos.sbr_fim, FormatTypes.decimals, 2),
      percent_sbr: helperFormat(100, FormatTypes.decimals, 2),
      colorIndex: null,
    });
  const pieChartClasseData =
    isLoaded && alocClasse
      ? alocClasse
        .map((obj) => ({
          name: obj.classe !== '' ? obj.classe : 'Outros',
          value:
            typeof obj.alocacao === 'number'
              ? Number((obj.alocacao * 100).toFixed(2))
              : Number((Number(obj.alocacao) * 100).toFixed(2)),
        }))
        .sort((a, b) => (a.value > b.value ? -1 : b.value > a.value ? 1 : 0))
      : null;

  /** Define altura do gráfico: se for xl-> 500, caso contrário será 375 */
  const heightXL = 500;
  const heightLessXL = 375;
  const [chartHeight, setChartHeight] = useState(window.innerHeight > HEIGHT_BREAKPOINT? heightXL : heightLessXL);
  window.addEventListener("resize", () => {
    window.innerHeight > HEIGHT_BREAKPOINT ? setChartHeight(heightXL) : setChartHeight(heightLessXL);
  });

  const handleModalAvisoButton = () => {
    setOpenModalAviso(true);
  }
  const handleModalInfoButton = () => {
    setOpenModalInfo(true);
  }

  useEffect(()=>{
    if(aviso_carteira_explodida && !aviso_carteira_explodida?.houve_explosao){ // mostrar aviso se não houve explosÃÂ£o(escolher datas disponíveis)
      setOpenModalAviso(true);
    }
  }, [aviso_carteira_explodida]);
  const avisoDataFim = aviso_carteira_explodida?.data_fim_AAAAmmDD_cart_explodida.split("-").reverse().join("/");

  return (
    <>
      <Grid>
        <Header obj_datas={obj_datas} dataFim title="Carteira Explodida" data_fim_apenas/>
        <ContainerInfo>
          {aviso_carteira_explodida?.explosao_xml_com_data_fim_alterada ? (
            <ContainerSubtitle>
              Carteira explodida em: {avisoDataFim} (data mais recente disponível)
              <BotaoAbrirModal onClick={handleModalInfoButton}>Mostrar informações da explosão</BotaoAbrirModal>
            </ContainerSubtitle>
          ) :
          isLoaded && !aviso_carteira_explodida?.houve_explosao ? (
            (
              <ContainerSubtitle>
                Posição consolidada em: {avisoDataFim} (sem explosão)
              </ContainerSubtitle>
            )
            ) :
              <ContainerSubtitle>
                Carteira explodida em: {avisoDataFim}
                <BotaoAbrirModal onClick={handleModalInfoButton}>Mostrar informações da explosão</BotaoAbrirModal>
              </ContainerSubtitle>
          }
          <BotaoAbrirModal onClick={handleModalAvisoButton} style={{float: 'right'}}>Mostrar datas disponíveis para explosão</BotaoAbrirModal>
        </ContainerInfo>
        <Line />
      </Grid>
      <Grid container spacing={2} marginTop={"5px"} justifyContent="center" alignItems="center">
        <Grid
          item xs={12} lg={6} xl={5}
          container
          spacing={2}
          justifyContent="center"
          alignItems="center">
          <Grid item xs={12} sx={{ marginTop: 3 }}>
            <Subtitulo mb="8px" center>
              Alocação por Classe (exposição final)
            </Subtitulo>
            <GraficoPizza series={pieChartClasseData} height={chartHeight} />
          </Grid>
        </Grid>
        <Grid
          item xs={12} lg={6} xl={7}
          container
          spacing={3}
          sx={{ marginTop: 0 }}>
          <Grid item xs={12}>
            {/* <Subtitulo mb="8px">Retorno</Subtitulo> */}
            <EnhancedTable
              rows={rowsDadosAgrupados}
              columns={columnsDadosAgrupados}
              fontSize={12}
              height={400}
              lineBreak={true}
            />
          </Grid>
        </Grid>
      </Grid>
      <ModalAviso
        openModal={isLoaded && openModalAviso}
        setOpenModal={setOpenModalAviso}
        data={aviso_carteira_explodida?.datas_disponiveis ?? null}
        dataSelecionada={avisoDataFim}
        ativos_nao_existem_carteira={aviso_carteira_explodida?.ativos_nao_existem_carteira ?? null}
        houveExplosao={aviso_carteira_explodida?.houve_explosao ?? null}
      />
      <ModalInfo
        openModal={isLoaded && openModalInfo}
        setOpenModal={setOpenModalInfo}
        data={aviso_carteira_explodida?.infos_ativos_explodidos ?? null}
        dataSelecionada={avisoDataFim}
      />
      <LoadingModal loading={(!isLoaded && !isLoadingMinimized) ?? false} />
      <FixedLoadingButton loading={!isLoaded} isLoadingMinimized={isLoadingMinimized}/>
    </>
  );
}
