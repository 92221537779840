import { useCallback } from 'react';
import { Dayjs } from 'dayjs';
import 'dayjs/locale/pt-br';
import { compareDatesDDMMYYYY } from '../../services/helper';
import { IObjDatas } from '../../services/reducers/helperReducers';

export const useIsDisabled = (obj_datas: IObjDatas, config?: any) => {
let compareDates = 0;
let dateToCompare: any = null;
  if (!config?.calendarioUnico) {
    if(config?.type === 'ini') {
      compareDates = 1;
      dateToCompare = config?.datas?.data_fim;
    }
    if(config?.type === 'fim') {
      compareDates = -1;
      dateToCompare = config?.datas?.data_ini;
    }
  }

  const isDisabled = useCallback((date: Dayjs) => {
    const day = date.day();
    const dates = date.format('YYYY-MM-DD');
    const dateDDMMYYYY = date.format('DDMMYYYY');
    const dataTemp = new Date(dates);
    let dataLimiteMin = new Date('1999-01-01');
    let dataLimiteMax = new Date('2099-12-31');
    dataLimiteMin = new Date(
      obj_datas.data_mais_antiga_portfolio_AAAAmmDD,
    );
    dataLimiteMax = new Date(
      obj_datas.data_mais_recente_portfolio_AAAAmmDD,
    );

    return (
      day === 0 ||
      day === 6 ||
      obj_datas.datas_nao_uteis.includes(dates) ||
      dataTemp > dataLimiteMax ||
      dataTemp < dataLimiteMin ||
      (compareDates > 0 && compareDatesDDMMYYYY(dateToCompare, dateDDMMYYYY) <= 0) ||
      (compareDates < 0 && compareDatesDDMMYYYY(dateToCompare, dateDDMMYYYY) >= 0)
    );
  }, [config]);

  return isDisabled;
}