import { fetchModalPosCon, fetchPosCon } from './../api/posConAPI';
import { resetStateBuilder } from './helperReducers';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface IChaveModal {
  ativo: string;
  tipo_ativo: string;
  data_compra_AAAAmmDD: string;
}

interface IAtivosAgrupados {
  nome_ativo: string;
  classe: string;
  subclasse: string;
  tipo_ativo: string;
  sbr_ini: string;
  apl: string;
  res: string;
  evento: string;
  sbr_fim: string;
  rendimento: string;
  imposto_pago: string;
  provisao_IR_IOF: string;
  sli_fim: string;
  percent_sli: string;
  data_compra_AAAAmmDD: string;
  data_compra: string;
  ticker: string;
  chave_para_modal: IChaveModal;
}

interface IRetornosBenchmarks {
  nome_benchmark: string;
  Ret_Mes_benchmark: string;
  Ret_Ano_benchmark: string;
  Ret_3m_benchmark: string;
  Ret_6m_benchmark: string;
  Ret_12m_benchmark: string;
  Ret_24m_benchmark: string;
  Ret_36m_benchmark: string;
  Ret_periodo_benchmark: string;
}

export interface IRetornosAtivos {
  nome_ativo: string;
  classe: string;
  subclasse: string;
  Ret_Mes: string;
  Ret_Ano: string;
  Ret_3m: string;
  Ret_6m: string;
  Ret_12m: string;
  Ret_24m: string;
  Ret_36m: string;
  Ret_periodo: string;
  data_compra: string;
  percent_sli: string;
  retornos_benchmarks: IRetornosBenchmarks[];
  chave_para_modal: IChaveModal;
}

interface IDatasPosCon {
  data_ini?: string;
  data_fim?: string;
}

export interface IPosConState {
  isLoaded: boolean;
  loading: boolean;
  obj_datas: {
    data_ini: string;
    data_fim: string;
    data_ini2: string;
    datas_nao_uteis: string;
    data_mais_antiga_portfolio_AAAAmmDD: string;
    data_mais_recente_portfolio_AAAAmmDD: string;
  };
  loadingModal: boolean;
  datas_dados_ativos_agrupados: IDatasPosCon;
  dados_ativos_agrupados: IAtivosAgrupados[];
  retornos_ativos: IRetornosAtivos[];
  dadosModal: (number | string)[][];
}

const initialState: IPosConState = {
  isLoaded: false,
  loading: false,
  obj_datas: {
    data_ini: '',
    data_fim: '',
    data_ini2: '',
    datas_nao_uteis: '',
    data_mais_antiga_portfolio_AAAAmmDD: '',
    data_mais_recente_portfolio_AAAAmmDD: '',
  },
  loadingModal: false,
  datas_dados_ativos_agrupados: {},
  dados_ativos_agrupados: [],
  retornos_ativos: [],
  dadosModal: [],
};

export const posConSlice = createSlice({
  name: 'posCon',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    resetStateBuilder(builder, initialState);

    builder.addCase(fetchPosCon.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchPosCon.fulfilled, (state, action) => {
      state.loading = false;
      state.isLoaded = true;
      state.obj_datas = action.payload.PosCon?.obj_datas;
      state.dados_ativos_agrupados = action.payload.PosCon?.dados_ativos_agrupados;
      state.datas_dados_ativos_agrupados = action.payload.PosCon?.datas_dados_ativos_agrupados;
      state.retornos_ativos = action.payload.PosCon?.retornos_ativos;
    });
    builder.addCase(fetchPosCon.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(fetchModalPosCon.pending, (state) => {
      state.loadingModal = true;
    });
    builder.addCase(fetchModalPosCon.fulfilled, (state, action) => {
      state.loadingModal = false;
      state.dadosModal = action.payload.modais?.PosCon[0] ?? [];
    });
    builder.addCase(fetchModalPosCon.rejected, (state) => {
      state.loadingModal = false;
    });
  },
});

export default posConSlice.reducer;
