// ------------------------- LOGIN
export const logininputColor = 'white';	// cor do input
export const loginErrorColor = 'red';	// cor do texto senha errada
export const loginTermosDeUsoColor = '#E7E7E7';	// cor do texto ?Ao logar?
export const loginBoxBGColor = '#222933';	// cor da box do login
export const loginBGColor = '#222933';	// cor do background
export const loginButtonBgc = '#33CC9D';	// cor do botão, esqueci minha senha e ?termos de uso?
export const loginButtonColor = 'black';	// cor do texto botão ENTRAR
export const loginBorder = 'none';	// css border para box do login
export const loginUnderlineColor = '#bdbfc2'; // linha inferior do input
export const loginColor = 'white';	// cor do texto Acesse sua conta
export const loginUsuarioSenhaColor = 'white'; //cor do texto Usuário / E-mail e Senha

// ------------------------- GERAL
export const backgroundColor = '#F8F8F8'; // cor de fundo das páginas
export const titlePageFontColor = '#00684E';  // cor do titulo no header. Ex: Resumo Carteira, Performance Historica
export const titleFontColor = '#00684E';
// Cor dos headers da tabela
// cor botão e underline Gerar PDF e Trocar Carteira
export const subTitleFontColor = '#00684E'; // cor dos titulos das tabelas. Ex: Fluxo de Resgate
export const menuIconsColor = 'white';  // cor do icone user
export const iconsColor = '#00684E';  // cor dos icones no header (Trocar carteira, Gerar PDF, periodo, bench)
export const fontColor = '#555555';
// Cor do Home > Resumo carteira no header
// Cor do botão benchmarks
// Linha ?Total? na tabela PosCon
// Cor do texto gráfico Barra
// Cor do texto botão Instituição Fluxo de caixa
export const dropSelectorColor = '#3E4755'; // cor de fundo do dropdown dos benchs e datas
export const lineColor = '#bebebe';
// linha que separa header da página
// borda dos cards
export const bgcSecondary = '#FFFFFF';
// backgroundcolor do card
// Fontcolor dos benchmarks e botão APLICAR
// Fontcolor do seletor de data e modal datas
// Cor do tooltip dos gráficos
// Fontcolor do seletor Fluxo de caixa
export const nomeCarteiraColor = '#000000';

// ------------------------- SIDE MENU
export const bgcMain = '#171C24'; // cor do menu lateral e superior
export const bgcMenuDivider = '#171C24';  // cor da linha que separa cada item
export const borderRightMenu = '1px solid #171C24'; // linha que separa menu da página
export const fontColorMenu = '#F8F8F8'; // cor do texto
export const iconColorSideMenu = '#F8F8F8'; // cor do icone
export const tabGradientColor = '#F8F8F8' // cor do highlight

// ------------------------- SELETOR CARTEIRA
export const cardFontColor = 'black';

// ------------------------- RESUMO CARTEIRA
export const toggleSwitchColor = '#1976d2'; // cor do switch percent. Geralmente é igual o iconsColor
export const tableCellColor = '#545F6E';
// cor do título do card
// fontcolor das tabelas
export const tableCellDarkerColor = '#3E4755'; // cor do conteúdo do card

export const chartColors = ['#02D181', '#22B8CF', '#7950F2', '#BE4BDB', '#DB4B90', '#F59F00', '#FD7E14', '#4EAB2D', '#2F7716', '#721D87', '#450775'];
// cores Posicao e Rendimento por Classe
// cores dos graficos de pizza
// Cores das tabelas PosCon e Retorno por Ativo

// ------------------------- PERFORMANCE HISTORICA
export const sideBoxColor = '#00684E';  // cor do ano na tabela Retornos Nominais da Carteira x Benchmarks
export const barChartColor = '#02D181'; // cor do grafico Ganho Financeiro
export const lineChartColors = [  // cores do grafico Retorno Acumulado
  '#02D181',  // cor do grafico Evolucao do Patrimonio
  '#22B8CF',
  '#7950F2',
  '#BE4BDB',
  '#DB4B90',
  '#F59F00',
  '#FD7E14',
  '#4EAB2D',
  '#2F7716',
  '#721D87',
  '#450775',
];

// ------------------------- PosCon/RetornoAtivo
export const classColor = '#444444';  // cor da classe na tabela
export const hexAlphaLight = 0.3; // cor do total da classe
export const hexAlphaLighter = 0.15;  // cor do total da subclasse

// ------------------------- VALORES FIXOS
export const fontFamily = 'Lato';

export const topMenuBoxShadow =
  '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)';

export const defaultLegendColor = '#D9D9D9';  // cor lateral da Tabela Posição e Rendimento por Classe quando nao tem cor definida

export const disabledColor = '#00000061'; // cor do botao desabilitado

export const fontSizeTitle = '24px';
export const xsFontSize = '10px';
export const smFontSize = '12px';
export const mdFontSize = '16px';
export const lgFontSize = '20px';
export const xlFontSize = '28px';
export const lineHeight125 = '125%';

export const orange = '#EF770C';
export const red = '#EF0C0C';
export const primaryColor = '#193493';
export const lightBlue = '#23B0DC';
export const positiveGreen = '#26A69A';
export const negativeRed = '#e73c3c';

export const borderRadius = '10px';

export const xsPadding = '8px';
export const smPadding = '16px';
export const mdPadding = '24px';
export const lgPadding = '32px';
export const xlPadding = '40px';

export const xsMargin = '8px';
export const smMargin = '16px';
export const mdMargin = '24px';
export const lgMargin = '32px';
export const xlMargin = '40px';

export const mobileS = '320px';
export const mobileM = '375px';
export const mobileL = '425px';
export const mobileXL = '490px';
export const tablet = '768px';
export const laptop = '1024px';
export const laptopL = '1440px';
export const desktop = '2560px';
