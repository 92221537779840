import { useAppDispatch, useAppSelector } from './reduxHooks';
import { useSearchParams, useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { IParams, setParams } from './reducers/preferenceSlice';

const useParameters = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useAppDispatch();
  const [params, loggedIn] = useAppSelector((state) => [
    state.preference.params,
    state.preference.loggedIn,
  ]);
  const carteiraFavoritaStorage = window.localStorage.getItem(
    `carteiraFavorita-${(window as any).env.REACT_APP_NAME ?? 'comdinheiro'}`,
  );

  const location = useLocation();
  const path = location.pathname.substring(1);
  const [isNavigated, setIsNavigated] = useState(false)

  useEffect(() => {
    setIsNavigated(true);
  }, [location]);

  useEffect(() => {
    const searchParamsReduced: IParams = {
      carteira: searchParams.get('carteira')
        ? searchParams.get('carteira') ?? '-1'
        : carteiraFavoritaStorage ?? null,
        periodo: (searchParams.get('periodo') && searchParams.get('periodo') !== '') ? searchParams.get('periodo') ?? 'maximo' : 'maximo',
      };

    const today = new Date();
    const storage = window.localStorage.getItem(
      `token-${(window as any).env.REACT_APP_NAME ?? 'comdinheiro'}`,
    );
    const { value: sessionToken, expiration: tokenExpiration } = storage
      ? JSON.parse(storage)
      : { value: null, expiration: null };

    if (!isNavigated && !searchParamsReduced.carteira) return;
    if (loggedIn || (sessionToken && today.getTime() < tokenExpiration)) dispatch(setParams(searchParamsReduced));
  }, [searchParams, isNavigated]);

  useEffect(() => {
    let property: keyof IParams;
    let changed = false;
    for (property in params) {
      if (
        params[property] &&
        String(params[property]) !== searchParams.get(property) &&
        path !== '' &&
        path !== 'login'
      ) {
        changed = true;
        searchParams.set(property, String(params[property]));
      }
    }
    if (changed) {
      const newSearch = decodeURIComponent(searchParams.toString());

      // Utilizado no useHistory.ts, adiciona os benchmarks e IF no novo estado de navegação quando o periodo muda, para manter o state correto na history stack
      const IF_history = location?.state?.IF;
      const bench_resumo_cart_history = location?.state?.bench_resumo_cart;
      const bench_perf_hist_history = location?.state?.bench_perf_hist;

      const newState: { [key: string]: any } = {};

      if(IF_history){
        newState.IF = IF_history;
      }
      if(bench_resumo_cart_history){
        newState.bench_resumo_cart = bench_resumo_cart_history;
      }
      if(bench_perf_hist_history){
        newState.bench_perf_hist = bench_perf_hist_history;
      }
      navigate({ search: `?${newSearch}` },  { state: newState});
    }
  }, [params]);
};

export default useParameters;
