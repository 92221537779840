import { Grid } from '@mui/material';
import styled from 'styled-components';

export const ContainerWrapper = styled(Grid)`
  margin-top: 20px;
`;

export const CardCarteiras = styled.div`
  border: solid 1.5px;
  border-radius: 8px;
  color: ${({ theme }) => theme.cardFontColor};
  border-color: ${({ theme }) => theme.lineColor};
  padding: 20px;
  background-color: white;
  cursor: pointer;
  &:hover {
    box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.2);
  }
`;

export const TituloCarteira = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: ${({ theme }) => theme.mdFontSize};
  font-weight: bold;
  margin-bottom: 10px;
`;

export const CodigoCarteira = styled.div`
  font-size: ${({ theme }) => theme.smFontSize};
  font-weight: bold;
`;

export const Patrimonio = styled.div`
  font-size: ${({ theme }) => theme.smFontSize};
`;

export const Data = styled.div`
  font-size: ${({ theme }) => theme.smFontSize};
  margin-top: 10px;
`;
