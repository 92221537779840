import { AxiosError } from 'axios';
import { ICarteira, IPreferenceState } from '../reducers/preferenceSlice';
import {IPreferenceStyle} from '../PreferenceStyleInterface/IPreferenceStyle';
import { createAsyncThunk } from '@reduxjs/toolkit';
import qs from 'qs';

import { RootState } from '../store';
import mockPreference from '../mock/preference.json';
import mockCarteiraList from '../mock/carteiraList.json';
import api from './index';

interface IUsuarioFinal {
  id_master?: number;
  id?: number;
  username?: string;
  email_confirmado?: number;
  status?: string;
  env?: string;
  recadastrar_senha_proximo_acesso?: string;
}

interface IPreferenceAPI extends IPreferenceState {
  randomInfo?: string[];
  access_token?: string;
  expires_in?: number;
  msg?: string;
  codigo?: string;
  usuario_final?: IUsuarioFinal;
  preference_whitelabel?: IPreferenceStyle;
}

interface IListaCarteira {
  ListaCarteiras: ICarteira[];
  access_token?: string;
  expires_in?: number;
  msg?: string;
  codigo?: string;
}

interface IPreferenceWhitelabel {
  meta: Record<string, any>;
  response: Record<string, any>;
}

export const fetchPreference = createAsyncThunk<
  IPreferenceAPI,
  { username: string; password: string },
  { state: RootState }
>('preference/login', async (args, thunkAPI) => {
  if (process.env.REACT_APP_MOCK_API === 'true') return mockPreference;

  try {
    const data = qs.stringify({
      username: args.username,
      password: args.password,
      tipo_usuario: (window as any).env.REACT_APP_APIUSERTYPE,
      renew: '1',
      expires_in: (window as any).env.REACT_APP_APIUSERTYPE,
    });

    const response = await api(null, 'application/x-www-form-urlencoded').post(
      `API/EndPoint002.php?code=get_jwt_uf&tipo_usuario=${(window as any).env.REACT_APP_APIUSERTYPE}`,
      data,
    );

    return response.data;
  } catch (error) {
    const err = error as AxiosError;
    return thunkAPI.rejectWithValue(err.response?.data);
  }
});

export const fetchCarteiraList = createAsyncThunk<IListaCarteira, void, { state: RootState }>(
  'preference/carteiraList',
  async (args, thunkAPI) => {
    if (process.env.REACT_APP_MOCK_API === 'true') return mockCarteiraList;

    const data = JSON.stringify({
      abas: ['ListaCarteiras'],
    });

    const token = thunkAPI.getState().preference?.token.value ?? '';
    try {
      const response = await api(token).post(
        `API/EndPoint002.php?code=${(window as any).env.REACT_APP_APICODE}&service=CarregaCoreExtratoWhitelabel001`,
        data,
      );
      return response.data;
    } catch (error) {
      const err = error as AxiosError;
      return thunkAPI.rejectWithValue(err.response?.data);
    }
  },
);

export const fetchPreferenceWhitelabel = createAsyncThunk<IPreferenceWhitelabel, void, { state: RootState }>(
  'preference/preferenceWhitelabel',
  async (args, thunkAPI) => {
    const token = thunkAPI.getState().preference?.token.value ?? '';
    try {
      const response = await api(token).get(
        `API/EndPoint002.php?code=${(window as any).env.REACT_APP_APICODE}&service=CarregaPreferenceWhitelabel`);
      return response.data;
    } catch (error) {
      const err = error as AxiosError;
      return thunkAPI.rejectWithValue(err.response?.data);
    }
  },
);

export const sendAnalyticsData = createAsyncThunk<void, string, { state: RootState }>(
  'preference/sendAnalyticsData',
  async (link, thunkAPI) => {
    const token = thunkAPI.getState().preference?.token.value ?? '';

    const data = qs.stringify({
      URL: link,
    });

    await api(token, 'application/x-www-form-urlencoded').post(
      `API/EndPoint002.php?code=${(window as any).env.REACT_APP_APICODE}&service=RegistraPageviewWhitelabel001`,
      data,
    );
  },
);

export const changePassword = createAsyncThunk<void, string, { state: RootState }>(
  'preference/changePassword',
  async (password, thunkAPI) => {
    const token = thunkAPI.getState().preference?.token.value ?? '';

    const data = qs.stringify({
      password,
    });

    try {
      await api(token, 'application/x-www-form-urlencoded').post(
        `API/EndPoint002.php?code=${(window as any).env.REACT_APP_APICODE}&service=RecadastraSenha001`,
        data,
      );
    } catch (error) {
      const err = error as AxiosError;
      return thunkAPI.rejectWithValue(err.response?.data);
    }
  },
);
