import { resetStateBuilder, IObjDatas } from './helperReducers';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchPerfHist } from '../api/perfHistAPI';
import { fetchPerfHistPerf } from '../api/perfHistAPI';
import { RootState } from '../store';

interface IMovimentacoes {
  data: string | number;
  saldoBrutoIni: string | number;
  apl: string | number;
  res: string | number;
  imp: string | number;
  saldoMovim: string | number;
  rend: string | number;
  saldoBruto: string | number;
}

export interface IRendimentoSubclasse {
  subclasse: string;
  rend: number;
  rend_ativo: { ativo: string; rend: number }[];
}

export interface IRendimento {
  classe: string;
  rend: string | number;
  rend_subclasse?: IRendimentoSubclasse[];
}

export interface IPerfHistState {
  isLoaded?: boolean;
  loading?: boolean;
  obj_datas: IObjDatas;
  resumo?: {
    saldoIni: number | string;
    saldoFim: number | string;
    impostospagos: number | string;
    rendimentoBruto: number | string;
    movimentacoes: number | string;
  };
  dadosMesaMes: (number | string)[][];
  graficoRetornos: (number | string)[][];
  graficoPL: (number | string)[][];
  movimentacoes: IMovimentacoes[];
  rendimento12meses: { [periodo: string]: IRendimento[] };
  selectedBenchmarks: string[] | null;
  loadingPercent: boolean;
  dadosPercentPerf?: {
    [periodo: string]: [
      {
        classe: string;
        perf: number;
        perf_subclasse?: [
          {
            subclasse: string;
            perf: number;
            perf_ativo: [{ ativo: string; perf: number }];
          },
        ];
      },
    ];
  };
}

const initialState: IPerfHistState = {
  isLoaded: false,
  loading: false,
  obj_datas: {
    data_ini: '',
    data_fim: '',
    data_ini2: '',
    datas_nao_uteis: '',
    data_mais_antiga_portfolio_AAAAmmDD: '',
    data_mais_recente_portfolio_AAAAmmDD: '',
  },
  dadosMesaMes: [],
  graficoRetornos: [],
  graficoPL: [],
  movimentacoes: [],
  rendimento12meses: {},
  selectedBenchmarks: null,
  loadingPercent: false,
};

export const perfHistSlice = createSlice({
  name: 'perfHist',
  initialState,
  reducers: {
    setSelectedBenchmarks: (state, action) => {
      // se estiver carregado com os benchmarks null, foram utilizados os benchmarks do CV_bench_mes_a_mes
      if(state.isLoaded && state.selectedBenchmarks !== null){
        state.selectedBenchmarks = action.payload;
        if (state.selectedBenchmarks === action.payload) {
          state.isLoaded = false;
        }
      }else{  // se j� estiver carregado com os benchs da preferencia, n�o recarregar
        state.selectedBenchmarks = action.payload;
      }
    },
    resetSelectedBenchmarks: (state) => {
      state.selectedBenchmarks = initialState.selectedBenchmarks;
    },
  },
  extraReducers: (builder) => {
    resetStateBuilder(builder, initialState, ['selectedBenchmarks']);

    builder.addCase(fetchPerfHist.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchPerfHist.fulfilled, (state, action) => {
      state.loading = false;
      state.isLoaded = true;
      state.obj_datas = action.payload.PerfHist?.obj_datas;
      state.dadosMesaMes = action.payload.PerfHist?.dadosMesaMes;
      state.graficoRetornos = action.payload.PerfHist?.grafico_retornos;
      state.graficoPL = action.payload.PerfHist?.grafico_pl;
      state.movimentacoes = action.payload.PerfHist?.movimentacoes;
      state.rendimento12meses = action.payload.PerfHist?.rendimento_12meses;
    });
    builder.addCase(fetchPerfHist.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(fetchPerfHistPerf.pending, (state) => {
      state.loadingPercent = true;
    });
    builder.addCase(fetchPerfHistPerf.fulfilled, (state, action) => {
      state.loadingPercent = false;
      state.dadosPercentPerf = action.payload.PerfHistPerf;
    });
    builder.addCase(fetchPerfHistPerf.rejected, (state) => {
      state.loadingPercent = false;
    });
  },
});

export const { setSelectedBenchmarks, resetSelectedBenchmarks } = perfHistSlice.actions;

export const selectToken = (state: RootState) => state.preference.token;

export default perfHistSlice.reducer;
