import { resetStateBuilder, IObjDatas } from './helperReducers';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchIFsCaixa, fetchFluxoCaixa } from '../api/fluxoCaixaAPI';
import { RootState } from '../store';

export interface IFluxoCaixaState {
  isLoaded: boolean;
  loading: boolean;
  isLoadedLista: boolean;
  loadingLista: boolean;
  obj_datas: IObjDatas;
  listaIFs: string[];
  IF: string;
  fluxo_de_ativos_caixa: (string | number)[][];
}

const initialState: IFluxoCaixaState = {
  isLoaded: false,
  loading: false,
  isLoadedLista: false,
  loadingLista: false,
  obj_datas: {
    data_ini: '',
    data_fim: '',
    data_ini2: '',
    datas_nao_uteis: '',
    data_mais_antiga_portfolio_AAAAmmDD: '',
    data_mais_recente_portfolio_AAAAmmDD: '',
  },
  listaIFs: [],
  IF: '',
  fluxo_de_ativos_caixa: [],
};

export const fluxoCaixaSlice = createSlice({
  name: 'fluxoCaixa',
  initialState,
  reducers: {
    setIF: (state, action: PayloadAction<string>) => {
      if (state.IF !== action.payload) {
        state.IF = action.payload;
        state.fluxo_de_ativos_caixa = [];
      }
    },
  },
  extraReducers: (builder) => {
    resetStateBuilder(builder, initialState, ['IF', 'listaIFs'], true);

    builder.addCase(fetchFluxoCaixa.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchFluxoCaixa.fulfilled, (state, action) => {
      state.loading = false;
      state.isLoaded = true;
      state.obj_datas = action.payload.FluxoCaixa?.obj_datas;
      state.fluxo_de_ativos_caixa = action.payload.FluxoCaixa?.fluxo_de_ativos_caixa;
    });
    builder.addCase(fetchFluxoCaixa.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(fetchIFsCaixa.pending, (state) => {
      state.loadingLista = true;
    });
    builder.addCase(fetchIFsCaixa.fulfilled, (state, action) => {
      state.loadingLista = false;
      state.isLoadedLista = true;
      state.listaIFs = action.payload.IFsCaixa?.IFsCaixa;
    });
    builder.addCase(fetchIFsCaixa.rejected, (state) => {
      state.loading = false;
    });
  },
});

export const { setIF } = fluxoCaixaSlice.actions;

export const selectToken = (state: RootState) => state.preference.token;

export default fluxoCaixaSlice.reducer;
