import { DefaultTheme } from 'styled-components';
import * as vars from './vars';

const lightTheme: DefaultTheme = {
  colors: {
    main: vars.primaryColor,
    secondary: vars.lightBlue,
  },
  primaryColor: vars.primaryColor,
  secondaryColor: vars.lightBlue,
  backgroundColor: vars.backgroundColor,
  backgroundColorMain: vars.bgcMain,
  backgroundColorMenuDivider: vars.bgcMenuDivider,
  borderRightMenu: vars.borderRightMenu,
  backgroundColorSecondary: vars.bgcSecondary,
  lineColor: vars.lineColor,
  barChartColor: vars.barChartColor,
  lineChartColors: vars.lineChartColors,
  chartColors: vars.chartColors,
  defaultLegendColor: vars.defaultLegendColor,
  disabledColor: vars.disabledColor,
  tabGradientColor: vars.tabGradientColor,
  toggleSwitchColor: vars.toggleSwitchColor,
  nomeCarteiraColor: vars.nomeCarteiraColor,

  topMenuBoxShadow: vars.topMenuBoxShadow,

  loginColor: vars.loginColor,
  logininputColor: vars.logininputColor,
  loginErrorColor: vars.loginErrorColor,
  loginTermosDeUsoColor: vars.loginTermosDeUsoColor,
  loginBGColor: vars.loginBGColor,
  loginBoxBGColor: vars.loginBoxBGColor,
  loginButtonBgc: vars.loginButtonBgc,
  loginButtonColor: vars.loginButtonColor,
  loginBorder: vars.loginBorder,
  loginUnderlineColor: vars.loginUnderlineColor,
  loginUsuarioSenhaColor: vars.loginUsuarioSenhaColor,

  cardFontColor: vars.cardFontColor,
  fontColorMenu: vars.fontColorMenu,
  iconColorSideMenu: vars.iconColorSideMenu,
  titlePageFontColor: vars.titlePageFontColor,
  titleFontColor: vars.titleFontColor,
  subTitleFontColor: vars.subTitleFontColor,
  sideBoxColor: vars.sideBoxColor,
  menuIconsColor: vars.menuIconsColor,
  iconsColor: vars.iconsColor,
  fontColor: vars.fontColor,
  dropSelectorColor: vars.dropSelectorColor,
  tableCellColor: vars.tableCellColor,
  tableCellDarkerColor: vars.tableCellDarkerColor,
  classColor: vars.classColor,
  hexAlphaLight: vars.hexAlphaLight,
  hexAlphaLighter: vars.hexAlphaLighter,

  fontFamily: vars.fontFamily,
  fontSizeTitle: vars.fontSizeTitle,
  xsFontSize: vars.xsFontSize,
  smFontSize: vars.smFontSize,
  mdFontSize: vars.mdFontSize,
  lgFontSize: vars.lgFontSize,
  xlFontSize: vars.xlFontSize,
  lineHeight125: vars.lineHeight125,

  positiveGreen: vars.positiveGreen,
  negativeRed: vars.negativeRed,

  xsPadding: vars.xsPadding,
  smPadding: vars.smPadding,
  mdPadding: vars.mdPadding,
  lgPadding: vars.lgPadding,
  xlPadding: vars.xlPadding,

  xsMargin: vars.xsMargin,
  smMargin: vars.smMargin,
  mdMargin: vars.mdMargin,
  lgMargin: vars.lgMargin,
  xlMargin: vars.xlMargin,

  borderRadius: vars.borderRadius,

  device: {
    mobileS: `(max-width: ${vars.mobileS})`,
    mobileM: `(max-width: ${vars.mobileM})`,
    mobileL: `(max-width: ${vars.mobileL})`,
    mobileXL: `(max-width: ${vars.mobileXL})`,
    tablet: `(max-width: ${vars.tablet})`,
    laptop: `(max-width: ${vars.laptop})`,
    laptopL: `(max-width: ${vars.laptopL})`,
    desktop: `(max-width: ${vars.desktop})`,
  },

  transitions: {
    easing: {
      easeOut: 'cubic-bezier(0.0, 0, 0.2, 1)',
      sharp: 'cubic-bezier(0.4, 0, 0.6, 1)',
    },
    duration: {
      leavingScreen: '195ms',
      enteringScreen: '225ms',
    },
  },
};

export { lightTheme };
