import React, { useState, useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { store } from './services/store';
import { createGlobalStyle, ThemeProvider } from 'styled-components';
import { BrowserRouter } from 'react-router-dom';
import { StyledEngineProvider } from '@mui/material/styles';
import App from './App'; // import reportWebVitals from './reportWebVitals';
import { lightTheme } from './style/theme';
import './index.css';
import { useAppSelector, useAppDispatch } from './services/reduxHooks';
import {setPreferenceStyle} from './services/reducers/preferenceSlice';

const GlobalStyle = createGlobalStyle`
  body {
    font-family: 'Open Sans';
    margin: 0;
    padding: 0;
    background-color: ${(props) => props.theme.backgroundColor};
    font-size: ${(props) => props.theme.mdFontSize};

    @media ${(props) => props.theme.device.mobileXL} {
      font-size: ${(props) => props.theme.smFontSize};
    }
  }
`;

function Main() {
  const dispatch = useAppDispatch();

  const [didLoadTheme, setDidLoadTheme] = useState(true); // n�o mostrar a tela logada sem estar com o tema carregado
  const [didMount, setDidMount] = useState(false);  // n�o mostrar a tela de login sem estar com o estilo carregado
  const [theme, setTheme] = useState(lightTheme); // inicializa com o tema padr�o

  const [
    preferenceStyle,
  ] = useAppSelector((state) => [
    state.preference.preferenceStyle,
  ]);

  useEffect(() => { // caso esteja rodando no localhost, utiliza as vari�veis do .env. Quando roda em prod, utiliza as variaveis do index.php
    if(process.env.NODE_ENV === 'development'){
      (window as any).env = {
        REACT_APP_APIUSERTYPE: process.env.REACT_APP_APIUSERTYPE,
        REACT_APP_APICODE:  process.env.REACT_APP_APICODE,
        REACT_APP_NAME:  process.env.REACT_APP_NAME,
        REACT_APP_TABNAME:  process.env.REACT_APP_TABNAME,
        REACT_APP_PDFNAME:  process.env.REACT_APP_PDFNAME,
      }
    }
  }, []);

  useEffect(() => {
    const storedPreference = JSON.parse(window.localStorage.getItem('preferenceStyle') || 'null'); // caso o usu�rio recarrege a p�gina, obtem os dados do localStorage
    const storedPreferenceLogin = JSON.parse(window.localStorage.getItem('preference-login') || 'null');
    if(storedPreference){  // se possui tema no localStorage, o usu�rio est� logado
      dispatch(setPreferenceStyle({...storedPreference, ...storedPreferenceLogin}));
    }else{  // carrega apenas o estilo da p�gina de login
      dispatch(setPreferenceStyle(storedPreferenceLogin));
    }
  }, []);

  useEffect(() =>{
    if(preferenceStyle){
      setDidLoadTheme(false);
      window.localStorage.setItem('preferenceStyle', JSON.stringify(preferenceStyle));
      const newTheme = {...theme, ...preferenceStyle?.estilo, ...preferenceStyle?.login};
      setTheme(newTheme);
      setDidLoadTheme(true);
      setDidMount(true);
    }
  }, [preferenceStyle])

  if(didMount && didLoadTheme){
    return (
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <GlobalStyle />
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </ThemeProvider>
      </StyledEngineProvider>
    );
  }else{
    return (
      <div></div>
    )
  }
}

const root = createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <Provider store={store}>
    <Main />
  </Provider>
);
