import React, { useState } from 'react';
import { useAppSelector, useAppDispatch } from '../../../services/reduxHooks';
import CalendarioField from '../../../components/Periodo/Calendario';
import { setDataPersonalizada } from '../../../services/reducers/preferenceSlice';
import { StyledButton, ButtonWrapper } from './style';
import { IObjDatas } from '../../../services/reducers/helperReducers';

interface Props {
  setOpenModal: (value: boolean) => void;
  obj_datas: IObjDatas;
}

export default function Calendario({ setOpenModal, obj_datas }: Props) {
  const dispatch = useAppDispatch();
  const [dataInicialCarteira, dataFinalCarteira] = useAppSelector((state) => [
    state.preference.dateStart,
    state.preference.dateEnd,
  ]);
  const [dataInicial, setDataInicial] = useState<string>(dataInicialCarteira);
  const [dataFinal, setDataFinal] = useState<string>(dataFinalCarteira);

  return (
    <>
      <CalendarioField
        dataInicial={dataInicial}
        dataFinal={dataFinal}
        setDataInicial={setDataInicial}
        setDataFinal={setDataFinal}
        obj_datas={obj_datas}
      />
      <ButtonWrapper>
        <StyledButton onClick={() => setOpenModal(false)}>CANCELAR</StyledButton>
        <StyledButton
          disabled={!(dataInicial && dataFinal)}
          onClick={() => {
            dispatch(setDataPersonalizada({ dataIni: dataInicial, dataFim: dataFinal }));
            setOpenModal(false);
          }}>
          APLICAR
        </StyledButton>
      </ButtonWrapper>
    </>
  );
}
