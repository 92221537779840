import React, { FormEvent, useEffect } from 'react';
import { useNavigate, useSearchParams, useLocation } from 'react-router-dom';
import LoginComponent from '../../components/Login';

import { fetchPreference } from '../../services/api/preferenceAPI';
import { useAppDispatch, useAppSelector } from '../../services/reduxHooks';

function LoginPage() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const redirect = searchParams.get('redirect');

  const token = useAppSelector((state) => state.preference.token);
  const carteiraFavoritaStorage = window.localStorage.getItem(
    `carteiraFavorita-${(window as any).env.REACT_APP_NAME ?? 'comdinheiro'}`,
  );

  const [loginError, loading] = useAppSelector((state) => [
    state.preference.loginError,
    state.preference.loading,
  ]);

  useEffect(() => {
    document.title = `${(window as any).env.REACT_APP_TABNAME} - Login`;
  }, []);

  useEffect(() => {
    if (token.value) {
      const queryParams = new URLSearchParams(location.search);
      queryParams.delete('redirect');
      navigate(`/${redirect ?? ''}?${queryParams.toString()}`);
      // window.location.replace(`${window.location.origin}/${redirect ?? ''}?${queryParams.toString()}`)
      if (carteiraFavoritaStorage && !searchParams.get('carteira')) {
        navigate(`/${redirect ?? ''}resumo-da-carteira?carteira=${carteiraFavoritaStorage}`);
        // window.location.replace(`${window.location.origin}/${redirect ?? ''}resumo-da-carteira?carteira=${carteiraFavoritaStorage}`);
      }
    }
  }, [token]);

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const formData = new FormData(event.target as HTMLFormElement);
    const email = formData.get('email') as string;
    const password = formData.get('password') as string;
    localStorage.setItem('emailLogin', email)
    dispatch(fetchPreference({ username: email, password }));
  };

  return token.value === '' ? (
    <LoginComponent loading={loading} loginError={loginError} handleSubmit={handleSubmit} />
  ) : null;
}

export default LoginPage;
